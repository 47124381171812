<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="订单状态">
              <a-select placeholder="请选择订单状态" v-decorator="['status', { initialValue: queryParam.status }]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="option in order_status_type" :key="option" :value="option">{{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="支付状态">
              <a-select placeholder="请选择支付状态" v-decorator="[
                'pay_status',
                {
                  initialValue: queryParam.pay_status
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="option in pay_status_type" :key="option" :value="option">{{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="user_nick_name" slot-scope="text, record">
        <a :href="'/clients/detail?id=' + record.user_id">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goShowOrder(record)">查看</a>
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { orderList } from '@/api/order'
import merge from 'webpack-merge'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true
  },
  {
    title: '订单号',
    dataIndex: 'ordernum'
  },
  {
    title: '日期',
    dataIndex: 'create_time',
    sorter: true,
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '客户名称',
    dataIndex: 'user.nick_name',
    scopedSlots: { customRender: 'user_nick_name' }
  },
  {
    title: '付款方式',
    dataIndex: 'payment_method'
  },
  {
    title: '金额',
    dataIndex: 'amount',
    sorter: true
  },
  {
    title: '支付状态',
    dataIndex: 'pay_status',
    sorter: true
  },
  {
    title: '状态',
    dataIndex: 'status'
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      columns,
      // 查询参数
      queryParam: {
        status: (this.$route.query.status ? this.$route.query.status : ''),
        pay_status: (this.$route.query.pay_status ? this.$route.query.pay_status : '')
      },
      order_status_type: [],
      pay_status_type: [],
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    console.log(1)
  },
  mounted() {
    console.log(2)
    this.fetch(this.queryParam)
  },
  watch: {
    $route(val) {
      console.log(val)
      this.queryParam.pay_status = this.$route.query.pay_status
      console.log(this.queryParam)
    },
    queryParam: {
      handler(val, oldVal) {
        this.fetch(this.queryParam)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      const parameter = {}
      parameter.status = this.form.getFieldValue('status')
      parameter.pay_status = this.form.getFieldValue('pay_status')
      this.submitLoading = true
      this.fetch(parameter)
      this.submitLoading = false
      this.$router.push({
        query: merge(this.$route.query, parameter)
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        orderList(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        this.order_status_type = data.order_status_type
        this.pay_status_type = data.pay_status_type
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    goShowOrder(record) {
      this.$router.push({ 'name': 'OrderEdit', query: { id: record.id } })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({ status: '', pay_status: '' })
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    }
  }
}
</script>
